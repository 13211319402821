<template>
  <v-container fluid class="down-top-padding">
    <base-breadcrumb :title="page.title" :icon="page.icon" :breadcrumbs="breadcrumbs"></base-breadcrumb>
    <base-card heading="Adicionar Enunciado">
      <form-wordings action="add"></form-wordings>
    </base-card>
  </v-container>
</template>
<script>
export default {
  data: () => ({
    page: {
      title: 'Enunciado'
    },
    breadcrumbs: [
      {
        text: 'Início',
        disabled: false,
        to: '/'
      },
      {
        text: 'Enunciado',
        disabled: false,
        to: '/question/list'
      },
      {
        text: 'Adicionar Enunciado',
        disabled: false,
        to: '/question/add/wordings'
      }
    ]
  })

}
</script>
